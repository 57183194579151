.spacing {
  margin-bottom: 100px;
}

.paragraph {
  margin-bottom: 20px;
  font-size: 1.1em;
  /* font-weight: bold; */
}

.title {
  font-size: 1.5em;
  font-weight: bold;
}

.aboutPage {
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  height: 100%;
}

.intro-text {
  padding: 3%;
  font-size: 1.4em;
}

.intro-text b {
  font-size: 1.8em;
}

.unordered-list li {
  font-size: 0.8em;
}

.unordered-list p {
  text-align: center;
}

.remaining-text h3 {
  font-size: 1.2em;
}