* {
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* IE 11 */
}

html {
  min-height: 100%;
}

body {
  background: url("https://ik.imagekit.io/zg1nptznhmc/sky_4AShhgcoK.jpg");
  background-size: auto ;
  background-color: gray;
  color: white;
}

.container-fluid {
  padding-right: 5px;
  padding-left: 5px;
}

h1,
h2,
h3 {
  margin: 30px;
  color: white;
  /* display: flex; */
  justify-content: center;
  margin: 20px;
}

h2 {
  font-size: calc(16px + 0.5vw);
}

.app-title {
  font-size: calc(15px + 0.5vw);
  font-weight: 400;
  font-style: italic;
}

.app-link {
  margin: 10px;
  height: 70px;
  width: 200px;
  max-width: 100%;
}

.deityColumn {
  float: left;
  width: 50%;
  padding: 10px;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}

.third-party-button-rack {
  display: flex;
  justify-content: center;
  width: 20%;
  margin: auto;
}

.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, white, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: white;
  background-color: #19191e;
}

.signin-button {
  cursor: pointer;
  border-radius: 3px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signin-button:focus {
  box-shadow: 0 0 5px #ddd;
  outline: none;
}

.signin-button-google span {
  color: #7d7d7b;
}

.signin-button-google {
  background-color: white;
}

.signin-button-google:hover {
  background-color: #d8d8d8;
}

.signin-button-fb {
  background-color: #4267b2;
}

.signin-button-fb:hover {
  background-color: #2b467c;
}

.signin-button img {
  margin: 10px;
  width: 30px;
  height: 30px;
}

.signin-button span {
  width: 200px;
  font-size: 14px;
}

/* Clear floats after the columns */

.deityRow:after {
  content: "";
  display: table;
  clear: both;
}

.deityRow {
  background-color: rgba(35, 55, 77, 0.5);
  max-width: 100%;
  margin: 0px;
  max-height: 100%;
  margin-top: 20px;
}

.centered {
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

.app-heads {
  min-width: 15%;
  min-height: 20%;
  max-height: 100%;
  max-width: 130px;
}


.content-row {
  width: auto;
}

.navigationBtns button {
  margin: 15px auto;
  min-width: 100px;
  display: block;
  padding: 10px;
  border-radius: 20px;
  width: 10vw;
  font-size: 1em;
}

button:hover {
  filter: brightness(75%);
}

#signInBtn {
  background-color: #588bc4;
  border-color: #588bc4;
}

#signUpBtn {
  background-color: #e8784d;
  border-color: #e8784d;
}

#anonymousBtn {
  background-color: #1f2b39;
  border-color: #1f2b39;
}

#signInGoogleBtn {
  margin: 15px auto;
  background: url("../../assets/btn_google_signin_light_normal_web.png") no-repeat;
  height: 46px;
  width: 191px;
}

#signInGoogleBtn:hover {
  background: url("../../assets/btn_google_signin_light_focus_web.png") no-repeat;
}

#signInGoogleBtn:focus {
  background: url("../../assets/btn_google_signin_light_focus_web.png") no-repeat;
}

#signInGoogleBtn:active {
  background: url("../../assets/btn_google_signin_light_pressed_web.png") no-repeat;
}

#signInFacebookBtn {
  margin: 15px auto;
  background: url("../../assets/facebook_login_button.png") no-repeat;
  height: 46px;
  width: 191px;
}

#signed-in-btn {
  margin-top: 50px;
  width: 15vw;
  background-color: #478dca;
}

.row {
  margin-right: 0;
}

.app-add {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
