.login-page {
  background: url("https://ik.imagekit.io/zg1nptznhmc/sky_4AShhgcoK.jpg");
  background-size: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  color: white;
}

#container {
  padding: 5%;
  justify-content: center;
  align-items: center;
}

.contents {
  padding-top: 10%;
}

.mainLogo {
  max-width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

#submitButton {
  margin: 0 auto;
  display: block;
  padding: 10px;
  border-radius: 20px;
  width: 40%;
}

.form-group {
  padding: 5px;
  width: 100%;
}

.form-control {
  height: 10%;
  border-radius: 20px;
  background-color: white;
  color:white;
  border: none;
}

::placeholder {
  color: white;
}
