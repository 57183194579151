.swal-text {
  color: white !important;
}
.center-conversation {
  left: 50%;
  position: absolute;
  top: 60%;
  width: 80vw;
  transform: translateY(-50%) translateX(-50%);
}

.parent {
  position: relative;
  width: 98vw;
  height: 98vh;
}

.background-divinator {
  animation-name: fade;
  animation-duration: 1s;
  background-image: url("https://ik.imagekit.io/zg1nptznhmc/sky_4AShhgcoK.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  position: absolute;
  animation-name: fade;
  animation-duration: 1s;
}

.background-shakespeare {
  background-image: url("../../assets/theater.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  animation-name: fade;
  animation-duration: 1s;
}

@keyframes fade {
  from {
    filter: grayscale(100%);
  }
  to {
    filter: grayscale(0%);
  }
}
