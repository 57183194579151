.navBar {
  align-items: center;
  min-height: 10vh;
  background-color: rgba(35, 55, 77, 0.4);
}
@media (hover: none) {
  .navBar {
    background-color: rgba(35, 55, 77, 1);
  }
}
.nav-image {
  max-height: 80px;
  overflow: hidden;
  padding-left: 0px;
}

.navbar-nav {
  font-size: 1.2em;
  justify-content: space-evenly !important;
}

.nav-link {
  color: white !important;
}

.navbar-toggler-icon {
  background-image: url("/assets/hamburgerIcon.png") !important;
}
