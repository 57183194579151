@media (max-width: 600px) {
  .float-heads {
    display: none;
  }
}
.float-heads {
  position: absolute;
  padding: 15px;
  top: 20%;
  border-radius: 20px;
  z-index: 1;
}

.head-unstacked div {
  animation-name: unstack;
  animation-duration: 1s;
  position: relative;
}

.block-button {
  display: block;
  border-radius: 50%;
  padding: 5px;
}

.greyed-out {
  animation-name: fadeGrey;
  animation-duration: 1s;
  filter: grayscale(100%);
  cursor: pointer;
}

.focused-head {
  z-index: 2;
}

.head-stacked .rest-head {
  opacity: 0;
}

/* Standard syntax */

@keyframes fadeGrey {
  from {
    filter: grayscale(0%);
  }
  to {
    filter: grayscale(100%);
  }
}
