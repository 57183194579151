.whiteFont {
    color: white
}

.divinator-font-color {
    color: darkorange
}

.shakespeare-font-color {
    color: #76AEE0
}

.chat-pair {
    /* padding: 20px; */
}

.chat-pair ul {
    /* padding: 0px; */
}

.conversation-list::-webkit-scrollbar {
    width: 0px;
}

.conversation-list:focus {
    outline: 0;
}