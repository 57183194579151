.App {
  text-align: center;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: grey;
}

.nav-links {
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}
