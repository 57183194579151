  .userName-tip {
      text-align: left;
      padding-top: 5px;
      color: white;
  }
  
  .queryBarContainer-small {
      width: 80%;
      margin: auto;
      animation-name: shrink;
      animation-duration: .5s;
  }
  
  .queryBarContainer-big {
      width: 100%;
      margin: auto;
      animation-name: grow;
      animation-duration: .5s;
  }
  
  .queryBar-thin {
      width: 100%;
      color: white;
      background: none;
      border: none;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      animation-name: toThinBar;
      animation-duration: .5s;
  }
  
  @keyframes toThickBar {
      from {
          border-bottom-width: 1px;
          border-bottom-style: solid;
      }
      to {
          border-bottom-width: 3px;
          border-bottom-style: solid;
      }
  }
  
  #queryBar input:focus {
      outline: 0;
  }
  
  .queryBar-thick {
      width: 100%;
      color: white;
      background: none;
      border: none;
      border-bottom-width: 3px;
      border-bottom-style: solid;
      animation-name: toThickBar;
      animation-duration: .5s;
  }
  
  @keyframes toThinBar {
      from {
          border-bottom-width: 3px;
          border-bottom-style: solid;
      }
      to {
          border-bottom-width: 1px;
          border-bottom-style: solid;
      }
  }
  
  @keyframes shrink {
      to {
          width: 80%
      }
      from {
          width: 100%
      }
  }
  
  @keyframes grow {
      from {
          width: 80%
      }
      to {
          width: 100%
      }
  }
  /* #queryBar div {
        animation-name: shrink;
        animation-duration: .5s;
    }
    

    
  
    
    #queryBar div {
        width: 80%;
        animation-name: shrink;
        animation-duration: .5s;
    }
    
    #queryBar div:focus {
        width: 100%;
        animation-name: grow;
        animation-duration: .5s;
    }
 
    #queryBar .userName-tip {
        text-align: left;
    }
        
    .userName-tip {
        text-align: left;
        margin: auto;
    }
    
*/