.sign-up-page {
  background: url("https://ik.imagekit.io/zg1nptznhmc/sky_4AShhgcoK.jpg");
  background-size: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  color: white;
}

#container {
  padding: 15vh;
  justify-content: center;
  align-items: center;
}

.mainLogo {
  max-width: 500px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

#signUpBtn {
  margin: 0 auto;
  display: block;
  border-radius: 20px;
  width: 10vw;
  background-color: #e8784d;
  border-color: #e8784d;
}

.form-group {
  padding: 5px;
}

.form-control {
  height: 5vh;
  border-radius: 10px;
  background-color: rgba(35, 55, 77);
  border: none;
  color:white
}

::placeholder {
  color: white;
}
